<template>
    <TransitionRoot
        as="template"
        :show="isOpen"
    >
        <Dialog
            class="relative z-50"
            @close="closable ? close() : () => {}"
        >
            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in duration-200"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
                <a
                    v-if="closable"
                    class="absolute top-0 right-0 mt-4 mr-4 p-4 cursor-pointer"
                    @click="close()"
                >
                    <icon-times class="w-4 h-auto transition-colors text-white hover:text-gray-300" />
                </a>

                <div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                    <TransitionChild
                        as="template"
                        enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-t-2xl sm:rounded-none bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-full sm:p-6 sm:mx-8"
                            :class="size"
                        >
                            <div>
                                <div :class="{ 'text-center': center }">
                                    <DialogTitle
                                        as="h3"
                                        class="font-serif text-base uppercase"
                                    >
                                        <slot name="title" />
                                    </DialogTitle>
                                    <div
                                        class="mb-4"
                                        :class="{ 'mt-2': slots.title }"
                                    >
                                        <slot name="default" />
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="slots.actions"
                                class="pt-5 sm:pt-6 px-6 -mx-6 shadow-top"
                            >
                                <slot name="actions" />
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import IconTimes from "~/public/icons/times.svg";

import { ref } from "vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";

withDefaults(
    defineProps<{
        size?: string;
        center?: boolean;
        closable?: boolean;
    }>(),
    {
        size: "sm:max-w-sm",
        center: true,
        closable: true,
    },
);

const slots = useSlots();
const isOpen = ref(false);

const toggle = () => {
    isOpen.value = !isOpen.value;
};

const open = () => {
    isOpen.value = true;
};

const close = () => {
    isOpen.value = false;
};

defineExpose({
    toggle,
    open,
    close,
});
</script>

<style scoped>
.shadow-top {
    box-shadow: 0 -2px 4px 0 #0000000d;
}
</style>
